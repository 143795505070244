import React, { useContext, useEffect, useRef } from "react";
import { Helmet } from 'react-helmet';
import { Section } from "../../../components/Core";
import { Col, Container, Row } from "react-bootstrap";
import GlobalContext from "../../../context/GlobalContext";
import Link from "gatsby-link";
import HeroSupportListView from "../../../components/Rebrand/Hero/HeroSupportListView";
import styled from "styled-components";
import { getProperty } from "./../../../utils/helperFn";
import { graphql, useStaticQuery } from "gatsby";

const LinkItem = styled(Link)`
  flex: 1 1 calc(50% - 10px);
  margin: 5px 0;
  box-sizing: border-box;
  padding: 8px 0;
  text-align: left;
  font-size: 1.2rem;
  @media (max-width: 991px) { 
    flex: 1 1 calc(100%);
  }
`

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
`

const SupportListView = (props) => {

  const gContext = useContext(GlobalContext);

  const data = props.data ? getProperty(props, 'data') : {}
  const categoryData = getProperty(data, 'strapiFaqCategories')
  const articles = getProperty(categoryData, 'faq_articles')

  return (
    <>
      <Helmet>
        <title>YouFibre Knowledge base</title>
        <meta name="title" content="YouFibre Knowledge base" />
        <meta name="description" content="YouFibre Knowledge base" />
      </Helmet>
      <HeroSupportListView categoryData={categoryData}/>
      <Section>
        <Container>
          <Row>
            <Col sm={12}>
              <FlexWrapper>
                {
                  categoryData?.faq_articles?.map((article, index) => {
                    return (
                      <LinkItem key={index} to={`/knowledge-base/article/${article.article_slug}`}>{article.title}</LinkItem>
                    )
                  })
                }
              </FlexWrapper>
              
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export const data = graphql`
  query getCategoryForId($page_slug: String) {
    strapiFaqCategories(page_slug: {eq: $page_slug}) {
      id
      page_slug
      title
      faq_articles {
        article_slug
        title
      }
    }
}`

export default SupportListView